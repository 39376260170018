import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import GithubCorner from "react-github-corner";
import Web3Utils from "web3-utils";
import getWeb3 from "../getWeb3";
// import Logo from "/images/logo.png";

export class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="multisend-container">
          <div className="flex flex-wrap gap-20 md:flex-row flex-col">
            <div className="flex flex-col lg:flex-3">
              <div className="flex gap-20 mb-30 items-center">
                <div>
                  <img src="images/logo_footer.png" />
                </div>
                <h4>Power by ZEDXION Smart Chain</h4>
              </div>
              <p className="mb-30">
                ZedDrop utilizes airdrops and bulk token sender mechanisms, streamlining token distribution to
                recipients, fostering wider adoption, and enhancing community engagement within its decentralized
                ecosystem.
              </p>
              <div className="flex flex-wrap gap-15 mb-30">
                <a href="https://zed-pay.com/" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/NeoBankIcon.svg"></img>
                  <span>NeoBank (ZedPay)</span>
                </a>
                <a href="https://www.zedxion.io/en_US/" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/Cex1Icon.svg"></img>
                  <span>CEX1 (ZEDXIONI)</span>
                </a>
                <a
                  href="https://www.zedcex.com/en_US/market"
                  className="flex gap-5 flex-[50%] sm:flex-auto"
                  target="_blank"
                >
                  <img src="images/Cex1Icon.svg"></img>
                  <span>CEX2 (ZedCex)</span>
                </a>
                <a href="https://zedscan.net/" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/ZedScanIcon.svg"></img>
                  <span>ZedScan</span>
                </a>
                <a href="https://zeddex.com" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/ZedDexIcon.svg"></img>
                  <span>ZedDex</span>
                </a>
                <a href="https://bridge.zedxion.com" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/ZedBridgeIcon.svg"></img>
                  <span>ZedBridge</span>
                </a>
                <a href="https://stake.zedxion.com" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/ZedStakeIcon.svg"></img>
                  <span>ZedStake</span>
                </a>
                <a href="https://token.zedxion.com" className="flex gap-5 flex-[50%] sm:flex-auto" target="_blank">
                  <img src="images/ZedTokenIcon.svg"></img>
                  <span>ZedToken</span>
                </a>
              </div>
              <div className="flex flex-wrap gap-20 items-center">
                <a className="flex items-center" href="https://www.facebook.com/zedxioncoin" target="_blank">
                  <img src="images/FacebookIcon.svg"></img>
                </a>
                <a className="flex items-center" href="https://twitter.com/zedxionc" target="_blank">
                  <img src="images/TwitterIcon.svg"></img>
                </a>
                <a className="flex items-center" href="https://www.instagram.com/zedxion/" target="_blank">
                  <img src="images/InstagramIcon.svg"></img>
                </a>
                <a className="flex items-center" href="#" target="_blank">
                  <img src="images/YoutubeIcon.svg"></img>
                </a>
                <a className="flex items-center" href="#" target="_blank">
                  <img src="images/DiscordIcon.svg"></img>
                </a>
                <a className="flex items-center" href="https://medium.com/subscribe/@zedxion_exchange" target="_blank">
                  <img src="images/MediumIcon.svg"></img>
                </a>
                <a className="flex items-center" href="#" target="_blank">
                  <img src="images/LinkedinIcon.svg"></img>
                </a>
                <a className="flex items-center" href="#" target="_blank">
                  <img src="images/RedditIcon.svg"></img>
                </a>
              </div>
            </div>
            <div className="flex flex-col lg:flex-1">
              <h5 className="mb-24">Company</h5>
              <a href="#" className="mb-12">
                Delegate to ZedScan
              </a>
              <a href="#" className="mb-20">
                <span className="btn-staking">Staking</span>
              </a>
              <a href="#" className="mb-20">
                Brand Assets
              </a>
              <a href="#" className="mb-20">
                Contact Us
              </a>
              <a href="#" className="mb-20">
                Terms & Privacy
              </a>
              <a href="#" className="mb-20">
                Bug Bounty
              </a>
            </div>
            <div className="flex flex-col lg:flex-1 min-w-[150px]">
              <h5 className="mb-24">Community</h5>
              <a href="#" className="mb-20">
                API Documentation
              </a>
              <a href="#" className="mb-20">
                Knowledge Base
              </a>
              <a href="#" className="mb-20">
                Network Status
              </a>
              <a
                href="https://docs.zedscan.net/general/learn-about-the-fuse-platform"
                className="mb-20"
                target="_blank"
              >
                Learn ZEDSCAN
              </a>
            </div>
            <div className="flex flex-col lg:flex-1">
              <h5 className="mb-24">Products & Services</h5>
              <a href="#" className="mb-20">
                Advertise
              </a>
              <a href="#" className="mb-20">
                Explorer-as-a-Service (EaaS)
              </a>
              <a href="#" className="mb-20">
                API Plans
              </a>
              <a href="#" className="mb-20">
                Priority Support
              </a>
              <a href="#" className="mb-20">
                Blockscan
              </a>
            </div>
          </div>
          <div className="flex flex-wrap gap-20 py-20">
            <p>ZEDXION Chain © 2024</p>
            <p>|</p>
            <a href="https://docs.zedscan.net/zedxion-whitepaper/" target="_blank" className="text-whitepaper">
              WhitePaper
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
